.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    flex-direction: column;
}

.spinner {
    width: 75px;
    height: 75px;
    border: 9px solid transparent;
    border-top-color: steelblue;
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Adjust animation duration as needed */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(90); /* Acceleration phase */
    }
    100% {
        transform: rotate(360deg); /* Deceleration phase */
    }
}

.loading-label {
    font-size: 1em;
    color: steelblue;
    font-weight: bold;
    margin-top: 15px;
}
