.home-page {
    text-align: center;
    font-family: Arial, sans-serif;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-page h1 {
    font-size: 2vw;
    margin-bottom: 10px;
    color: black;
}

.home-page p {
    font-size: 1.2vw;
    margin-bottom: 20px;
    color: black;
}

.logout-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #0056b3;
}

.logout-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0,123,255,0.5);
}

.logout-button:active {
    background-color: #004494;
}

.main-title {
    font-size: 3.5em;
    font-weight: bold;
    color: black;
}

/* styles.css */
.signup-button {
    background-color: #4682B4; /* Darkish blue color */
    color: white;
    padding: 10px 30px;
    font-size: 1vw;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    width: 30%;
    height: auto;
    max-lines: 1;
    display: inline-block;
}

.signup-button:hover {
    background-color: #2a6493; /* Slightly darker blue for hover */
}

.signup-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(30, 58, 138, 0.5); /* Outline for accessibility */
}

.signup-button:active {
    background-color: #0F1B3D; /* Even darker blue for active state */
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3); /* Inset shadow for click effect */
}

.main-content {
    transition: opacity 0.5s, visibility 0.5s;
    visibility: visible;
}

.fade-out {
    opacity: 0;
    visibility: hidden;
}

.fade-in {
    animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.hidden {
    display: none;
}

.modal-content {
    position: fixed;
    color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f8f9fa;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 40vw;
    width: 90%;
    z-index: 1000;
    opacity: 1; /* Ensure full opacity */
}

.signup-form {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-input {
    background-color: #454545;
    color: black;
}

.user-form-input {
    font-size: 1.15vw;
    background-color: #ffffff;
    /* border: none; */
    border-radius: 2px;
    color:'#454545';
    border: solid 1px #999999;
}

.button-group {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.submit-button {
    background-color: #4682B4;
    border: none;
    padding: 6px 20px;
    color: #fff;
    transition: background-color 0.3s;
    font-size: 1em;
}

.submit-button:hover {
    background-color: #2a6493;
}

.cancel-button {
    background-color: gray;
    border: none;
    height: auto;
    font-size: 5px;
    padding: 12px 20px;
    color: #fff;
    transition: background-color 0.3s;
    font-size: 1em;
}

.cancel-button:hover {
    background-color: lightgray;
}

@media (max-width: 600px) {
    .modal-content {
        padding: 20px;
    }
    .signup-form {
        gap: 15px;
    }
    .button-group {
        flex-direction: column;
        align-items: stretch;
    }
    .submit-button, .cancel-button {
        width: 100%;
        margin-top: 10px;
    }
}

.form-control::placeholder { 
    color: black;
    opacity: 1; 
}

.form-control:-ms-input-placeholder { 
    color: black;
}

.form-control::-ms-input-placeholder { 
    color: black;
}

.user-form-input::placeholder { 
    color: black;
    opacity: 1;
}

.user-form-input:-ms-input-placeholder { 
    color: black;
}

.user-form-input::-ms-input-placeholder { 
    color: black;
}


/* styles.css */
.start-button {
    margin: 10px;
    background-color: #4682B4; /* Darkish blue color */
    color: white;
    padding: 8px 30px;
    font-size: 1.25em;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    width: 40%;
    max-lines: 1;
    display: inline-block;
}

.start-button:hover {
    background-color: #0056b3; /* Slightly darker blue for hover */
}

.start-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(30, 58, 138, 0.5); /* Outline for accessibility */
}

.start-button:active {
    background-color: #0F1B3D; /* Even darker blue for active state */
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3); /* Inset shadow for click effect */
}

.slider-header {
    margin-top: 20px;
}


.question-label {
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
    color: #454545;
    text-align: center;
    margin-top: 20px;

}

/* .radio-no {
    margin-right: 5px;
    margin-top: 5px;
    margin-left: 10px;
    color: #454545;
}

.radio-yes {
    margin-right: 5px;
    margin-top: 5px;
    color: #454545;

} */

.question-input {
    text-align: center;
    font-size: 2em;
    text-decoration: none;
    border: none;
    outline: none;
}

.parq-title {
    font-size: 2vw;
    font-weight: bold;
}

.label-link{
    text-decoration: underline;
}
.label-link:hover{
    cursor: pointer;
}

.radio-label {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
}

.radio-custom {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    position: relative;
}

.radio-yes:checked + .radio-custom {
    background-color: #0078d7; /* Blue background */
    border-color: #0078d7;
}

.radio-no:checked + .radio-custom {
    background-color: #ccc; /* Gray background */
    border-color: #ccc;
}

.radio-custom::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: white;
    position: absolute;
    top: 3px;
    left: 3px;
    opacity: 0;
    transition: opacity 0.2s;
}

.radio-yes:checked + .radio-custom::before {
    opacity: 1;
}

.radio-no:checked + .radio-custom::before {
    opacity: 1;
}

input[type="radio"] {
    display: none;
}

.activity-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 3 columns */
    gap: 20px; /* Space between grid items */
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.option-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px; /* Space between grid items */
    list-style-type: none;
    padding: 0;
    margin: 0;
}


.calendar-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 3 columns */
    gap: 20px; /* Space between grid items */
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.activity-grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.activity-card {
    padding: 20px;
    border-radius: 10px; /* Rounded corners for a smoother look */
    width: 100%; /* Full width within grid item */
    height: 12vw; /* Maintain a flexible height */
    max-width: 100%; /* Ensure it doesn't exceed the grid item width */
    max-height: 100%;
    background-color: #ffffff55; /* Semi-transparent white background */
    border: 1px solid transparent; /* Invisible border for consistent size */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activity-card:hover {
    transform: scale(1.05); /* Scale up slightly on hover */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
    cursor: pointer;
}

.activity-card-img {
    margin-bottom: 10px;
    width: 50%;
    height: 50%;
    color: black;
}

.activity-grid-item {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.activity-card-checkbox {
    position: relative;
    margin-left: 100%;
    height: 20px;
    margin-top: 10px;
    cursor: pointer;
}


.activity-card {
    background-color: #ffffff;
    border-radius: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activity-card-img {
    color: #676767;
    border-radius: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.activity-card-title {
    color: #676767;
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 10px;
    
}

.custom-checkbox-group .custom-checkbox input[type="checkbox"] {
    transform: scale(2); /* Increase checkbox size */
    cursor: pointer; /* Change cursor to pointer */
}

.custom-checkbox-group .custom-checkbox input[type="checkbox"]:checked {
    background-color: #2f3b4a; /* Change checkbox background color when checked */
    border-color: #2f3b4a; /* Change border color when checked */
}

.custom-checkbox-group .custom-checkbox input[type="checkbox"]:checked + label {
    color: #2f3b4a; /* Change label color when checkbox is checked */
}
