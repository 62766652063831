
.profile-page {
    padding: 20px;
    background-color: transparent;
    border-radius: 5px;
    text-align: center;

    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.profile-page ul {
    background-color: transparent;
    list-style-type: none;
    padding: 0;
    margin: 0;
    
}

.card:hover{
    scale: 1.02;
    cursor: pointer;
    transition: 0.3s;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 2 columns */
    gap: 20px; /* Gap between grid items */
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    background-color: #ffffff55;
    color: black;
    outline-color: transparent;
    border-color: transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    scale: 1;
    transition: 0.2s;

    justify-content: center;
    align-items: center;
}

.card-img{
    margin-bottom: 10px;
    width: 40%;
    height: 40%;
}

.card-icon{
    margin-bottom: 10px;
    width: 5vw;
    height: 5vw;
    color: black;
}

.card h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.card p {
    margin: 5px 0;
    color: black;

}
.card-title{
    margin-top: 10px;
    font-weight: bold;
    color: black;

}
html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.history-card{
    width:80vw;
    height:20vh;
    padding:0 1vw 0 1vw;
    border-radius:5px;
    background:#ffffff33;

    scale: 1;
    transition: 0.2s;
    color: black;

}

.history-card:hover{
    scale: 1.05;
    cursor: pointer;
    transition: 0.3s;
}

.history-item{
    width: 100%;
    background-color: #fafafa;
    height: 20vh;
}

.side-container{
    width: 100%;
    background-color: #efefef;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction :'column';
}

.stats-label{
    font-size: 1.5vw;
    color: #454545;
    margin-left: 2vw;
}

.edit-icon{
    scale: 1;
    transition: 0.2s;

}

.edit-icon:hover{
    scale:1.05;
    transition: 0.3s;
    cursor: pointer;
    color:steelblue
}

.section-buttons-white{
    margin-bottom: 0;

    width: 100%;
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.2s;
}

.section-buttons-white:hover{
    opacity: 0.65;
    transition: 0.3s;
    cursor: pointer;
}