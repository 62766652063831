/* Import Normalize.css for consistent styling across browsers */
@import 'normalize.css';

/* Global styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100% !important;
    width: 100% !important;
    font-family: Arial, sans-serif;
    background-color: transparent;
    color: #333;
    scale: 1 !important;
    overflow-x: hidden;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    scale: 1 !important;

}

.content {
    flex: 1; /* This allows the content to grow and push the footer to the bottom */
    padding: 20px;
}

a {
    text-decoration: none;
    color: inherit;
}

header, main, footer {
    width: 100vw;
}
