.label-title{
    font-size: 2em;
    color: #f1f1f1;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 6vw;
    color: steelblue;
    text-align: left;
}

.img1{
    background-image: url('../homephoto.jpg');
}

.title-div{
    background: linear-gradient(
        ellipse at 10% 90%,
        #f0c9a2 ,
        #3769ae
    ); 
}

.content-image{
    scale: 1;
    transition: transform 0.3s ease-in-out;
    transform: skew(-10deg);
}

.content-image:hover{
    transform: scale(1.1) skew(-10deg);
    cursor: pointer;
    transition: 0.3s;
}

.read-more-button{
    scale: 1;
    transition: 0.2s;

}

.read-more-button:hover{
    background-color: #3769ae;
    scale: 1.05;
    cursor:pointer;
    transition: 0.3s
}

.meet-the-team-container {
    text-align: center;
    padding: 20px;

    position: relative;
}

h1 {
    margin-bottom: 20px;
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.member-photo {
    border-radius: 50%;
    margin-bottom: 20px;
    object-fit: scale-down;
}


.arrow {
    width: 6vw;
    height: 6vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow.next {
    right: 50px; /* Adjust as needed */
}

.arrow.prev {
    left: 50px; /* Adjust as needed */
}

.email-input{
    border: 1px solid lightgray;
    outline: none;
    border-right-width: 0;
    color: steelblue;
    text-decoration: none;
    background-color: #f1f1f1;
    padding: 10px 20px;
    text-align: center;
}
.subscribe-button{
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left-width: 0;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.25vw;
    font-weight: bold;
    color: #565656;
    scale: 1;
    transition: 0.2s;
}

.subscribe-button:hover{
    padding: 7px 16px;
    border: 2px solid steelblue;
    background-color: #fff;
    cursor: pointer;
    color: #565656;

    scale: 1.025 1.05;
    transition: 0.3s;
}
/* .subscribe-div{
    scale: 1;
    transition: 0.3s;
}
.subscribe-div:hover{
    scale: 1.05;
    transition: 0.3s;
} */

.hexagon-grid {
    display: grid;
    grid-template-columns: repeat(3, 50px);
    grid-template-rows: repeat(2, 43.3px); /* adjust for vertical alignment */
    gap: 0px;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .hexagon {
    width: 50px;
    height: 28.87px; /* height of hexagon = width / 2 * sqrt(3) */
    background-color: #3caea3;
    margin: 7.71px 0;
    position: relative;
  }
  
  .hexagon:before,
  .hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
  }
  
  .hexagon:before {
    bottom: 100%;
    border-bottom: 14.43px solid #3caea3;
  }
  
  .hexagon:after {
    top: 100%;
    width: 0;
    border-top: 14.43px solid #3caea3;
  }