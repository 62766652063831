.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000; /* Ensure the header stays on top of other content */
}

.content {
    padding-top: 60px; /* Adjust this value to the height of your header */
    /* Other styles for your main content */
}


.left-section {
    display: flex;
    align-items: center;
}

.logo-icon {
    width: 100px; /* Adjust size as needed */
    margin-right: 20px;
}

.search-icon {
    width: 20px; /* Adjust size as needed */
    height: 20px;
}

.nav {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
}

.nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-item {
    position: relative;
    margin: 0 20px;
    display: flex;
    align-items: center;
}

.nav a {
    color: #555555;
    text-decoration: none;
    font-size: 1vw;
    padding: 5px 10px;

}

.nav a:hover {
    color: steelblue; /* Adjust hover color as needed */
}

.dropdown-icon {
    margin-left: 0;
    width: 15px;
    height: 15px;
    padding-left: 0; 
    color: #555555;
    transition: 0.2s;
}

.dropdown-icon:hover{
    color: steelblue;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
}

.right-section .profile-menu {
    display: flex;
    align-items: center;
    position: relative;
}

.right-section .dropdown-trigger .dropdown-menu {
    right: 0;
    left: auto;
}

.notification-icon,
.add-icon {
    width: 25px; /* Adjust size as needed */
    height: 25px;
    margin: 0 10px;
}

.profile-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profile-icon {
    width: 2vw; /* Adjust size as needed */
    height: 2vw;
    border-radius: 50%;
    margin-right: 3px;
}

.home-logo{
    font-size: 1.5vw;
    color: steelblue;
    font-weight: bold;
    margin-right: 15px;
}

.dropdown-trigger {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.dropdown-menu {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border-style: solid;
    border-width: 0 0 0 2px;
    border-color: steelblue;
    outline: none;
    z-index: 1000;
    list-style: none;
    /* padding: 0; */
    margin: 0;
    border-radius: 0;
    flex-direction: column;
}

.dropdown-option-active{
    border-style: solid;
    border-width: 0 0 0 2px;
    border-color: steelblue;
    color: steelblue;
}

.dropdown-menu li {
    padding: 0;
    width: 200px;

}

.dropdown-menu li a {
    text-decoration: none;
    color: #000;
    display: block;
    
}

.dropdown-menu li a:hover {
    background-color: #f0f0f0;
}

.dropdown-option{
    color: black;
    text-decoration: none;
    font-weight: normal;
    font-size: 1em;
    padding: 5px 10px;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}

.main-dropdown-option{
    color: #555555;
}