/* SessionReviewForm.css */
.session-review-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 50vw;
    margin: 0 auto;
    padding: 20px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    background: transparent;
    border-radius: 8px;
}

.form-stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin-bottom: 20px;
}

.form-stage h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #fff;
}

.slider {
    width: 80%;
    margin-bottom: 20px;
}

button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background: #1E3A8A;
}

button.selected {
    background: #333333;
    color: white;
}

button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

button:last-child {
    margin-left: auto;
}

@media (max-width: 768px) {
    .session-review-form {
        padding: 10px;
    }

    .form-stage h2 {
        font-size: 20px;
    }

    button {
        padding: 8px 16px;
    }
}

.form-back-button, .form-next-button {
    padding: 0.5em 1em;
    font-size: 1em;
    background-color: #4682B4dd;
    outline: none;
    border: none;
}

.form-back-button:hover, .form-next-button:hover {
    background-color: #295e8a;
}

.single-line-text {
    font-size: 30px;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    width: 100%; /* Ensure full width for centering */
    text-align: center; /* Center text horizontally */
    margin-bottom: 20px; /* Space below text */
}

.form-finish-button {
    padding: 10px 25px;
    position: relative;
    font-size: 25px;
    cursor: pointer;
    margin-top: 20px; /* Space above button */
    background-color: #142865aa;
}

.review-icon{
    width: 40px;
    height: 40px;

    scale: 1;
    transition: 0.2s;
}

.review-icon:hover{
    scale: 1.1;
    transition: 0.3s;
    cursor: pointer;
}