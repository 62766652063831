.background-home {
    background-image: url('../homephoto.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    margin: 0;
    background-color: black;
    padding: 0;
}

.background-story {
    background-image: url('../sunsetbikes.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    margin: 0;
    background-color: black;
    padding: 0;
}

.background-graywhite{
    background-color: #f1f1f1;
}

.background-splash {
    background-image: url('../wallpaper3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    margin: 0;
    background-color: black;
    padding: 0;
}


.background-blue{
    background-color: lightsteelblue;
}


.background-dashboard {
    background-color: #f1f1f1;
    height: 10000%;

}

.background-training {
    background-color: #f1f1f1; 
}

.background-blog{
    background: radial-gradient(
        ellipse at 10% 90%,
        #f0c9a2 ,
        #3769ae
    ); 
}

.background-plans {
    /* background: linear-gradient(to right, #3769ae, #d2d5db); */

    background: radial-gradient(
        ellipse at 10% 90%, /* Position the ellipse slightly away from the top right corner */
        #f0c9a2 ,           /* Inner color */
        #3769ae        /* Outer color */
    ); 
}

.background-profile {
    background: radial-gradient(
        ellipse at 10% 90%,
        #f0c9a2 ,
        #3769ae
    ); 
    /* background-image: url('../homephoto5.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    margin: 0;
    background-color: black;
    padding: 0; */
}

.background-about {
    background-color: #adc1dc;
}

.background-landing{
    background: linear-gradient(to bottom, #ffffff,#f1f1f1,#327bbf,#001a33);


}

.background-default {
    background: radial-gradient(
        ellipse at 10% 90%,
        #f0c9a2 ,
        #3769ae
    ); 
}

.background-white{
    background-color: whitesmoke;
}

/* Ensure the entire viewport is covered */
.background-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.app {
    flex: 1;
}

.content {
    flex: 1;
}
