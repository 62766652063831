
.blog-page {
    padding: 20px;
    background-color: transparent;
    border-radius: 5px;
    text-align: center;

    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.blog-page ul {
    background-color: transparent;
    list-style-type: none;
    padding: 0;
    margin: 0;
    
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 2 columns */
    gap: 20px; /* Gap between grid items */
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-card{
    /* background-image: url('../homephoto.jpg'); */
    height: 50vh;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 10px;
    background-color: #adc1dc;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 0px;
    border-radius: 6px;
    color: white;
    outline-color: transparent;
    border-color: transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    scale: 1;
    transition: 0.2s;
}

.blog-card:hover{
    scale: 1.02;
    cursor: pointer;
    transition: 0.3s;
}

.card-image{
    width: 200px;
    height: 200px;
}