
.plans-page {
    padding: 20px;
    background-color: transparent;
    /* border-radius: 5px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.plans-page ul {
    background-color: transparent;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.plan-card{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 0px;
    color: white;
    outline-color: transparent;
    border-color: transparent;

    display: flex;
    flex : 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: auto;
    width: auto;
    text-align: center;

    scale: 1;
    transition: 0.2s;
}
.plan-card-title{
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
}

.plan-image{
    width: 100px;
    height: 100px;
}

.plan-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 20px; 
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.plan-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    flex-direction: column;
    scale: 1;
    transition: 0.2s;
}

.plan-item:hover{
    scale: 1.025;
    cursor: pointer;
    transition: 0.3s;
}

.set-plan-button {
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    background-color: rgb(28, 83, 128);
    transition: 0.2s;
}

.set-plan-button:hover{
    background-color: rgb(18, 57, 88);
    transition: 0.3s;
}