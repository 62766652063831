
.dashboard-page {
    text-align: center;
    margin: 20px;
    font-family: Arial, sans-serif;

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 1;
    color: #454545;

    height: 75vh;
    
}

.dashboard-page h1 {
    margin-bottom: 10px;
    color: #454545;
}

.dashboard-page p {
    margin-bottom: 20px;
    color: #454545;
}

.dashboard-page h2 {
    color: #454545;
}

.main-content {
    transition: opacity 0.5s, visibility 0.5s;
    visibility: visible;

}

.fade-out {
    opacity: 0;
    visibility: hidden;
}

.fade-in {
    animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.hidden {
    display: none;
}

.modal-content {
    position: fixed;
    color: #454545;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
    background: #f8f9fa55;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 450px;
    width: 90%;
    z-index: 1000;
    
}

.signup-form {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-input {
    background-color: #ffffff33;
    /* border-radius: 5px; */
    border: 1px solid #ccc;
    padding: 12px;
    width: 100%;
    color: white;
    box-sizing: border-box;
    /* background: #fff; */
    font-size: 1em;
    border-color: white;
}

.button-group {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.submit-button {
    background-color: #1E3A8A;
    border: none;
    padding: 12px 20px;
    color: #fff;
    border-radius: 8px;
    transition: background-color 0.3s;
    font-size: 1em;
}

.submit-button:hover {
    background-color: #0056b3;
}

.cancel-button {
    background-color: gray;
    border: none;
    padding: 12px 20px;
    color: #fff;
    border-radius: 8px;
    transition: background-color 0.3s;
    font-size: 1em;
}

.cancel-button:hover {
    background-color: lightgray;
}

@media (max-width: 600px) {
    .modal-content {
        padding: 20px;
    }
    .signup-form {
        gap: 15px;
    }
    .button-group {
        flex-direction: column;
        align-items: stretch;
    }
    .submit-button, .cancel-button {
        width: 100%;
        margin-top: 10px;
    }
}

.form-control::placeholder { 
    color: white;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: white;
}

.form-control::-ms-input-placeholder {
    color: white;
}

.card-enlarged {
    padding: 20px;
    /* border-radius: 8px; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    height: 80vh;
    background-color: #ffffff55;
    color: white;

    justify-content: center;
    align-items: center;
    
}

.back-button{
    width:30px; 
    height:30px;

    scale: 1;
    transition: 0.2s;
}

.back-button:hover{
    scale: 1.1;
    cursor: pointer;
    transition: 0.3s;
}

.download-button{
    scale: 1;
    transition: 0.2s;
}

.download-button:hover{
    scale: 1.1;
    cursor: pointer;
    transition: 0.3s;
}

.completed-button {
    background-color: #1E3A8A; /* Darkish blue color */
    color: white;
    padding: 15px 30px;
    font-size: 1em;
    font-weight: bold;
    border: none;
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    width: 40%;
    max-lines: 1;
    display: inline-block;
}

.completed-button:hover {
    background-color: #142865; /* Slightly darker blue for hover */
}

.completed-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(30, 58, 138, 0.5); /* Outline for accessibility */
}

.completed-button:active {
    background-color: #0F1B3D; /* Even darker blue for active state */
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3); /* Inset shadow for click effect */
}

.dashboard-page ul {
    background-color: transparent;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@media (min-width: 768px) {
    .zone-hr-range, .zone-pace-range {
        display: table-cell;
    }
}
.strava-activities-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 20px;
}

.strava-activity-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: 'Arial', sans-serif;
    transition: transform 0.2s;
  }
  
  .strava-activity-card:hover {
    transform: translateY(-5px);
  }
  
  .activity-name {
    font-size: 1.2em;
    font-weight: bold;
    font-style: italic;
    color: #2f3b4a; /* Strava-like blue */
    margin-bottom: 8px;
  }
  
  .activity-detail {
    font-size: 1em;
    color: #333;
    margin: 4px 0;
  }
  
  .activity-type {
    font-size: 0.9em;
    color: #2f3b4a;
    font-weight: 600;
    margin-top: 8px;
    text-transform: capitalize;
  }
  

.day-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Always maintain 4 columns */
    gap: 20px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-width: calc(4 * (200px + 20px)); /* Adjust 200px to your card's width, and 20px is the gap */
    box-sizing: border-box;
    justify-content: start; /* Align items to the start to prevent wrapping issues */
}

.grid-item {
    width: 100%; /* Ensure items fill the column space */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}


.card {
    padding: 20px;
    border-radius: 0;
    width: 100%; /* Make the card take the full width of the grid item */
    max-width: 100%; /* Optional: max width to control card size */
    max-height: 100%;
    height: 20vw;

    background-color: #ffffff55;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 0px;
    color: white;
    outline-color: transparent;
    border-color: transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    scale: 1;
    transition: 0.2s;

    justify-content: center;
    align-items: center;
}

.card-img{
    margin-bottom: 10px;
    width: 30%;
    height: 30%;
    color: black;
}

.section-buttons{
    margin-bottom: 0;

    width: 100%;
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.2s;
}

.section-buttons:hover{
    opacity: 0.65;
    transition: 0.3s;
    cursor: pointer;
}

.button-icon{
    width: 2vw;
    height: 2vw;
    padding: 5px 50px;
    scale: 1;
    transition: 0.2s;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    z-index: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #fff;
    color: #544;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 135%; /* Position above the element */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.download-label{
    font-size: 1vw;
    transition: 0.2s;
}
.download-label:hover{
    font-size: 1.05vw;
    transition: 0.3s;
    cursor: pointer;
}

.review-button{
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.25vw;
    font-weight: bold;
    color: #565656;
    scale: 1;
    transition: 0.2s;
}

.review-button:hover{
    padding: 7px 16px;
    border: 2px solid steelblue;
    background-color: #fff;
    cursor: pointer;
    color: #565656;

    scale: 1.025 1.05;
    transition: 0.3s;
}

.strava-button{
    margin: 5px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.25vw;
    font-weight: bold;
    color: #565656;
    scale: 1;
    transition: 0.2s;
}

.strava-button:hover{
    padding: 7px 16px;
    border: 2px solid #fc4c02;
    background-color: #fff;
    cursor: pointer;
    scale: 1.025 1.05;

    transition: 0.3s;
}

.tooltip-container:hover .tooltiptext {
    visibility: visible;  /* Show tooltip on hover */
    opacity: 1;  /* Fully visible tooltip */
    z-index: 100000;
}

.form-next-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.25vw;
    font-weight: bold;
    color: #565656;
    scale: 1;
    transition: 0.2s;
}

.form-next-button:hover{
    padding: 7px 16px;
    border: 2px solid steelblue;
    background-color: #fff;
    cursor: pointer;
    color: #565656;

    scale: 1.025 1.05;
    transition: 0.3s;
}

.form-back-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.25vw;
    font-weight: bold;
    color: #565656;
    scale: 1;
    transition: 0.2s;
}

.form-back-button:hover{
    padding: 7px 16px;
    border: 2px solid steelblue;
    background-color: #fff;
    cursor: pointer;
    color: #565656;

    scale: 1.025 1.05;
    transition: 0.2s;
}

.dashboard-view-container{
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #f1f1f1;
}

.right-side-panel-container{
    display: flex;
    flex:1;
    background-color: #f1f1f1;
    z-index: 10000;
    margin-left:97%;

    padding-left: 0;
    transition: 0.2s;
}

.right-side-panel-container:hover{
    padding-left: 20px;
    background-color: white;
    cursor: pointer;
    transition: 0.2s;
}

.right-side-arrow-container{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    justify-items: center;
    background-color: white;

    padding-right: 0px;
    transition: 0.2s;
}

.right-side-arrow-container:hover{
    padding-right: 20px;
    cursor: pointer;
    transition: 0.2s;
}

.side-panel-container{
    display: flex;
    flex:1;
    background-color: #f1f1f1;
    z-index: 10000;

    padding-right: 0;
    transition: 0.2s;
}

.side-panel-container:hover{
    padding-right: 20px;
    background-color: white;
    cursor: pointer;
    transition: 0.2s;
}
.expand-arrow-container{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    justify-items: center;
    background-color: white;

    padding-left: 0px;
    transition: 0.2s;
}

.expand-arrow-container:hover{
    padding-left: 20px;
    cursor: pointer;
    transition: 0.2s;
}

.expand-arrow{
    width: 30px;
    height: 30px;
    z-index: 10000;

    color: #676767;
}

.calendar-container {
    position: fixed;
    top: 0;
    left: -200vw; /* Start off-screen */
    width: 90%;
    margin-left: 5%;
    height: 75vh;
    margin-top: 11.5vh;
    background-color: transparent;
    transition: left 0.2s ease-in-out;
    z-index: 1000; /* Adjust if necessary */

    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.calendar-container.show {
    left: 0; /* Slide in when 'show' class is applied */
}

.right-panel-container {
    position: fixed;
    top: 0;
    left: -100vw; /* Start off-screen */
    width: 90%;
    margin-left: 5%;
    height: 75vh;
    margin-top: 11.5vh;
    background-color: transparent;
    transition: left 0.2s ease-in-out;
    z-index: 1000; /* Adjust if necessary */
}

.right-panel-container.show {
    left: 0; /* Slide in when 'show' class is applied */
}

.main-panel-container{
    display: flex;
    flex:30;
    background-color: #f1f1f1;
    width: 100vw;
    /* height: 100vh; */
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* overflow-y: scroll; */
}

.top-panel{
    width: 98%;
    height: 30%;
    margin-top: 10vh;
    background-color: transparent;
    display: flex;
    margin-bottom: 3vh;
}

.streak-icon{
    color: white;
    width: 40px;
    height: 40px;
}

.streak-label{
    font-size: 1.2vw;
    color: #f1f1f1;
    font-weight: bold;
    margin-top: 20px;
}

.top-panel-fitness {
    display: flex;
    flex: 2;
    scale: 0.95;
    width: 100%;
    height: 100%;
    background: linear-gradient(145deg, #2f3b2f, #1a261a); /* Dark gray with a slight green tint to black with a slight green tint */
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.2), /* Subtle inner glow */
                0 4px 8px rgba(0, 0, 0, 0.5); /* Stronger outer shadow for depth */
    position: relative;
    overflow: hidden;
    cursor: pointer;

}

.top-panel-fitness:hover {
    cursor: pointer;
    transform: rotate(30deg) translateY(20%);
    opacity: 0.8;
}

.top-panel-streak {
    display: flex;
    flex: 1;
    /* background: linear-gradient(145deg, rgb(230, 235, 240), #b5c7dd); */
    background-color: #ffffff;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    scale: 1;
    
}

.top-panel-streak:hover {
    cursor: pointer;
    scale: 1.05;
    opacity: 0.8;
    transition: 0.3s;
}

.top-panel-daysleft {
    display: flex;
    flex: 1;
    /* background: linear-gradient(145deg, #d1d1d1, #ffffff); */
    background-color: #2f3b4a;
    border-radius: 5px;
    /* outline: 2px dashed #2f3b4aee; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    scale: 1;
    
}

.streak-icon {
    color: #f1f1f1;
    width: 25%;
    height: 25%;
}

.level-label {
    font-size: 1.5vw;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Adds depth to the text */
}

.icon-button{
    scale: 1;
    transition: 0.2s;
    display: flex; 
    width: 90%,; 
    padding: 15px; 
    padding-top:30px;
    padding-bottom:30px;
    width: 100%;
    border-radius: 8px; 
    justify-content: center; 
    align-items: center;

    flex-direction: column;
}

.icon-button:hover{
    scale: 1.1;
    transition: 0.3s;
    cursor: pointer;
}


.icon-button-icon{
    scale: 1.75;
    color: #2f3b4a;
    transition: 0.2s;
}

.icon-button-icon:hover{
    color: white;
    transition: 0.3s;
}

.calendar-icon {
    color: #2f3b4a;
    width: 25px;
    height: 25px;
}

.activity-button{
    scale: 1;
    transition: 0.2s;

}

.activity-button:hover{
    scale: 1.025;
    transition: 0.3s;
    cursor: pointer;
}

.cell-input {
    width: 100%;
    border: none;
    background: none;
    outline: none;
    text-align: center;
    color: inherit; /* Matches the text color of the surrounding elements */
    padding: 0;
    appearance: textfield; /* Removes spinners in some browsers */
    
  }