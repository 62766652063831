.custom-grid-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 15px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    margin: 0 auto; /* Center the grid */
    height: 100%;

}

.custom-grid-item {
    display: flex;
    
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border: 2px solid #ccc;
    border-radius: 8px;
    border-color: #2f3b4a33;
    padding: 20px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: grab;

    transition: 0.2s;
}

.custom-grid-item:hover {
    background-color: #2f3b4a33;
    transition: 0.3s;
}


.custom-card {
    display: flex;
    flex-direction: column; /* Stack the icon section on top of the content section */
    justify-content: center; /* Align content to the top */
    align-items: center; /* Center align the content horizontally */
    width: 100%; /* Ensure the card fills the grid item */
    height: 100%; /* Ensure the card fills the grid item */
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.custom-card-icon-section {
    display: flex;
    flex-direction: row; /* Icons are arranged horizontally */
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; /* Add spacing between the icon section and the content section */
    width: 100%; /* Ensure the section spans the width of the card */
}

.custom-card-content {
    display: flex;
    
    flex-direction: column; /* Ensure content is stacked vertically */
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure the section spans the width of the card */
}

.custom-grid-item:active {
    cursor: grabbing;
}

.dragging {
    transform: rotate(3deg);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
