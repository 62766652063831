.meet-the-team-container {
    text-align: center;
    padding: 20px;

    position: relative;
}

h1 {
    margin-bottom: 20px;
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.member-photo {
    border-radius: 50%;
    margin-bottom: 20px;
    object-fit: scale-down;
}


.arrow {
    width: 5vw;
    height: 5vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow.next {
    right: 50px; /* Adjust as needed */
}

.arrow.prev {
    left: 50px; /* Adjust as needed */
}

.story-image{
    scale: 1;
    transition: transform 0.3s ease-in-out;
    transform: skew(-5deg);
}
