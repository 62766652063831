.dashboard-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f1f1f1;
}

.patient-name-label {
    font-size: 1vw;
    padding: 10px;
    color: #343434;
}

.patient-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: column; /* Merged from general styles */
}

.patient-card {
    background-color: white; /* Merged styles */
    overflow: hidden;
    border-width: 0px 0px 3px 0px;
    border-style: solid;
    border-color: steelblue;
    transition: transform 0.2s, scale 0.2s, box-shadow 0.3s ease; /* Unified transitions */
}

.patient-card:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    scale: 1.001;
    transition: 0.3s;
}

.patient-image {
    width: 75%;
    object-fit: cover;
}

.patient-details {
    color: #f1f1f1;
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr; /* Merged layout */
    gap: 2px;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
}

.patient-details h3 {
    font-size: 1.2rem;
    color: #f1f1f1;
}

.patient-details p {
    font-size: 0.9rem;
    color: #f1f1f1;
    padding: 0;
    margin-bottom: 0;
}

.patient-column {
    padding: 5px;
    text-align: center;
    font-size: 14px;
    color: white;
}

.steel-blue {
    background-color: #fafafa;
}

.slate-gray {
    background-color: #fafafa;
}

.soft-red {
    background-color: #f08080;
}

.medium-sea-green {
    background-color: #3cb371;
}

.expanded-section {
    height: 0;
    overflow: hidden;
    background-color: #f9f9f9;
    border-top: 1px solid #ccc;
    transition: height 0.3s ease, padding 0.3s ease;
}

.expanded-section.expanded {
    height: auto;
    padding: 10px;
}

.expanded-content {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.expanded-section.expanded .expanded-content {
    opacity: 1;
}

.search-bar{
    outline: none;
    border: none;
    width: 95%;
    font-size: 1.25vw;
    margin-bottom: 15px;
    padding: 5px 10px;
    font-style: italic;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: steelblue;
}