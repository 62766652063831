.page {
    text-align: center;
    font-family: Arial, sans-serif;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.button{
    border-radius: 0;
    background-color: slategray;
    width: 20%;
    padding: 10px 40px;
    color: white;

    transition: 0.2s;
}

.button:hover{
    background-color: #2f3b4a;
    cursor: pointer;
    transition: 0.3s;
}

.stage-div{
    display: flex;
    width: 100%;
    height: 100vw;
    background-color: transparent;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;

}


.title{
    font-size: 2em;
    color: #454545;
    font-weight: bold;
    margin-bottom: 20px;
}

.prompt-input {
    width: 60vw;
    padding: 0px 10px;
    font-size: 16px;
}

.suggestions-box {
    position: absolute;
    border: 1px solid #ccc;
    background-color: white;
    width: 300px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
}

.week-div{
    display: flex;
    width: 40vw;
    height: 40px;
    background-color: #2f3b4a;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    scale: 1;
    transition: 0.2s;
}

.week-div:hover{
    scale: 1.05;
    transition: 0.3s;
    cursor: pointer;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

.back-arrow{
    scale: 1;
    transition: 0.2s;
}


.back-arrow:hover{
    scale: 1.05;
    transition: 0.3s;

    cursor: pointer;
}

.save-label{
    font-size: 1em;
    font-weight: bold;
    scale: 1;
    transition: 0.2s;
}

.save-label:hover{
    scale: 1.05;
    transition: 0.3s;
    cursor: pointer;
}

.icon-container {
    position: absolute;
    bottom: 10px; /* Adjust this value to position the squares as needed */
    display: flex;
    justify-content: center;
    width: 100%;
}

.plans-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Always maintain 4 columns */
  gap: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  scale: 0.9;
}

.icon-square {
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px; /* Adjust the margin for spacing between squares */
    border-radius: 4px; /* Adjust the border radius if needed */

    scale:1;
    transition: 0.2s;
}

.icon-square:hover{
    scale:1.05;
    transition: 0.3s;
}

.icon {
    color: #2f3b4a;
    width: 20px;
    height: 20px;
}

.trash-icon {
    position: absolute;
    top: 10px; /* Adjust this value to position the icon vertically */
    right: 10px; /* Adjust this value to position the icon horizontally */
    color: #777777; /* Adjust the color as needed */
    cursor: pointer;
    width: 30px; /* Adjust the width if necessary */
    height: 30px; /* Adjust the height if necessary */
}
.addStepContainer {
    display: flex;
    width: 100%;
    background-color: slategray;
    justify-content: center;
    align-items: center;
    padding: 10px;
    overflow:visible;
  }
  
  .flex-start {
    display: flex;
    flex: 5;
    justify-content: flex-start;
    align-items: center;
  }
  
  .flex-end {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
  
  .input, .label {
    padding: 5px 10px;
    background-color: slategray;
    color: white;
    text-decoration: none;
    outline: none;
    border: none;
    width: 60vw;
  }
  
  
  .add-step-button {
    background-color: #2f3b4a;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
  }

  .publish-button {
    background-color: #2f3b4a;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    scale:1;
    transition: 0.2s;
  }

  .publish-button:hover {
    scale: 1.05;
    transition: 0.3s;
  }

  .addStepContainer {
    position: relative;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background: white;
    z-index: 1000;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .dropdown-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background: #eee;
  }

  .popup{
    /* position: absolute; */
    background: transparent;
  }

  .popup-option{
    color: #454545;
    width: 18px;
    height: 18px;

    transition: 0.2s;
  }

  .popup-option:hover{
    color: #2f3b4a;
    cursor: pointer;
    transition: 0.3s;
  }

  .details-input-title{
    font-size: large;
    text-decoration: none;
    outline: none;
    padding: 5px 10px;
    width: 90%;
    color:#454545;
    border: none;
    outline: none;
  }

  .details-input-desc{
    font-size: medium;
    margin-top: 10px;
    padding: 5px 10px;
    width: 90%;
    color:#454545;
    max-height: calc(1.5em * 4); /* Limit height to approximately 4 lines */
    line-height: 1.5em; /* Adjust line-height for consistency */
    resize: none; /* Disable manual resizing */
    overflow-y: auto; /* Add vertical scrollbar if content exceeds max-height */
    padding: 8px; /* Add padding for better readability */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    border: none;
    outline: none;


  }

  .use-ai-icon{
    width: 30px;
    height: 30px;

    scale: 1;
    transition: 0.2s;

  }

  .use-ai-icon:hover{
    scale: 1.1;
    color:gold;
    transition: 0.3s;
  }

  .exercise-card-image img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}