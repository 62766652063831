.global-button {
    font-style: italic;
    font-weight: bold;
    color: #2f3b4a;
    background-color: #ffffff;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid; /* Add this */
    border-color: steelblue;
    padding: 5px 10px;

    transition: 0.2s;
}

.global-button:hover{
    color: #f1f1f1;
    background-color: steelblue;
    transition: 0.3s;
}

.plan-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f1f1f1;
}

.plan-name-label {
    font-size: 1vw;
    padding: 10px;
    color: #343434;
}

.plan-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: column; /* Merged from general styles */
}

.plan-card {
    background-color: white; /* Merged styles */
    overflow: hidden;
    border-width: 0px 0px 3px 0px;
    border-style: solid;
    border-color: steelblue;
    transition: transform 0.2s, scale 0.2s, box-shadow 0.3s ease; /* Unified transitions */
}

.plan-card:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    scale: 1.001;
    transition: 0.3s;
}

.plan-image {
    width: 75%;
    object-fit: cover;
}

.plan-details {
    color: #f1f1f1;
    display: grid;
    grid-template-columns: 40fr 1fr; /* Merged layout */
    gap: 2px;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
}

.plan-details h3 {
    font-size: 1.2rem;
    color: #f1f1f1;
}

.plan-details p {
    font-size: 0.9rem;
    color: #f1f1f1;
    padding: 0;
    margin-bottom: 0;
}

.plan-column {
    padding: 5px;
    text-align: center;
    font-size: 14px;
    color: white;
}

.steel-blue {
    background-color: #fafafa;
}

.slate-gray {
    background-color: #fafafa;
}

.soft-red {
    background-color: #f08080;
}

.medium-sea-green {
    background-color: #3cb371;
}

.expanded-section {
    height: 0;
    overflow: hidden;
    background-color: #ffffff;
    border-top: 1px solid #ccc;
    transition: height 0.3s ease, padding 0.3s ease;
}

.expanded-section.expanded {
    height: auto;
    padding: 10px;
}

.expanded-content {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.expanded-section.expanded .expanded-content {
    opacity: 1;
}

.search-bar{
    outline: none;
    border: none;
    width: 95%;
    font-size: 1.25vw;
    margin-bottom: 15px;
    padding: 5px 10px;
    font-style: italic;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: steelblue;
}

.item-div{
    scale: 1;
    transition: 0.2s;
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 2px solid steelblue;
    border-radius: 5px;
    margin-bottom: 10px;
}
.item-div:hover{
    scale:1.01;
    transition: 0.3s;
}

.arrow-left{
    scale: 1;
    transition: 0.2s;
}

.arrow-left:hover{
    scale: 1.05;
    transition: 0.3s;
    cursor: pointer;
}

.step-input{
    font-style: italic;
    font-weight: bold;
    color: #2f3b4a;
    outline: none;
    font-size: 14px;
    border: none;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 3px;
    position: relative; /* Ensure it's positioned within its parent */
    z-index: 1; /* Ensure inputs have a lower z-index than the dropdown */
}

.search-dropdown {
    position: absolute; /* Position the dropdown below the input */
    top: 100%; /* Place it directly below the input */
    left: 0;
    width: 100%;
    z-index: 10; /* Ensure the dropdown appears above other content */
    background-color: white; /* Optional for better visibility */
    border: 1px solid #ddd; /* Optional styling */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional styling */
    max-height: 200px; /* Limit the height */
    overflow-y: auto; /* Ensure scrolling if content exceeds max-height */
}

.search-dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
}

.search-dropdown-item:hover {
    background-color: #f1f1f1;
}
